<template>
    <div>
      <div v-if="!hideEnquiry" class="h-16 md:h-20"></div>
  
      <!-- banner 2nd -->
      <div class="bg-center bg-no-repeat bg-cover" style="background-image:url('/images/bg-banner.jpg')">
        <div class="container py-20 md:py-28 lg:py-40">
          <div>
            <h2 class="text-2xl font-semibold leading-tight text-center text-gray-900 md:text-4xl xl:text-5xl">
              Looking for <span class=" text-green-2">sustainable, customizable</span> <br class="hidden lg:block"> gifts for your team or your clients?
              <!-- Curated By The <span class=" text-green-2">Asli Co.</span> -->
            </h2>
            <p class="pt-3 text-sm leading-tight text-center text-gray-600 md:font-medium md:text-base xl:text-lg">Change the way you gift with corporate gifts that give back to the community.</p>
            <div class="bg-gray-700 w-0.5 h-6 my-4 mx-auto"></div>
            <div v-if="!hideEnquiry">
              <p class="py-2 mx-auto text-sm text-center text-white bg-gray-700 lg:text-base w-44">
               <a href="https://wa.me/60167703372?text=Hi,%20please%20send%20me%20your%20corporate%20gifting%20catalogue.%20Name:%20|%20Company:%20|%20Number%20of%20gift%20sets:%20|%20Budget:">Talk to a Gift Expert</a>
                </p>
                <p class="pt-1 text-sm text-center text-gray-700 underline lg:text-base">
                 <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }">Or, get a quote</a>
                  </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      hideEnquiry: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <!-- <style>
  @media (min-width: 768px){
    .bg-banner-2{
      background-image: url('../images/bg-product-1.jpg');
    }
  }
  </style> -->
  