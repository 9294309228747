var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('WaBtn'),_vm._m(0),_c('MainBanner'),_vm._m(1),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(2),_c('div',{staticClass:"flex flex-wrap pt-2 lg:justify-center"},_vm._l((_vm.choose_us),function(item,i){return _c('div',{key:i,staticClass:"w-1/2 pb-5 lg:w-1/4"},[_c('img',{staticClass:"w-20 mx-auto",attrs:{"src":item.image,"alt":item.alt}}),_c('div',{staticClass:"pt-3 leading-tight text-center text-gray-600"},[_c('p',[_vm._v(_vm._s(item.alt))])])])}),0)])]),_c('div',{staticClass:"pt-5 pb-10"},[_c('div',{staticClass:"container"},[_vm._m(3),_c('div',{staticClass:"pt-6"},[_c('carousel',{attrs:{"autoplay":true,"loop":true,"dots":false,"autoplayTimeout":5000,"responsive":{
          0: { items: 3, nav: false },
          600: { items: 5, nav: false },
          1000: { items: 6, nav: false },
        }}},_vm._l((_vm.testimonial),function(image){return _c('div',{key:image,staticClass:"px-1"},[_c('img',{attrs:{"src":image,"alt":""}})])}),0),_c('carousel',{attrs:{"autoplay":true,"loop":true,"dots":false,"autoplayTimeout":5700,"responsive":{
          0: { items: 3, nav: false },
          600: { items: 5, nav: false },
          1000: { items: 6, nav: false },
        }}},_vm._l((_vm.testimonial_2),function(image){return _c('div',{key:image,staticClass:"px-1"},[_c('img',{attrs:{"src":image,"alt":""}})])}),0),_c('carousel',{attrs:{"autoplay":true,"loop":true,"dots":false,"autoplayTimeout":5500,"responsive":{
          0: { items: 3, nav: false },
          600: { items: 5, nav: false },
          1000: { items: 6, nav: false },
        }}},_vm._l((_vm.testimonial_3),function(image){return _c('div',{key:image,staticClass:"px-1"},[_c('img',{attrs:{"src":image,"alt":""}})])}),0)],1)])]),_c('div',{staticClass:"py-10 bg-gray-50"},[_c('div',{staticClass:"container"},[_vm._m(4),_c('div',{},[_c('div',{staticClass:"p-3 border border-gray-200 lg:shadow-md md:flex md:flex-wrap"},_vm._l((_vm.product),function(item,i){return _c('div',{key:i,staticClass:"pb-4 lg:w-1/3 md:w-1/2 md:px-2"},[_c('hooper',{attrs:{"itemsToShow":1,"centerMode":true,"wheelControl":false}},[_vm._l((item.slides),function(slide,indx){return _c('slide',{key:indx,attrs:{"index":indx}},[_c('img',{staticClass:"w-full",attrs:{"src":slide,"alt":""}})])}),_c('hooper-navigation',{attrs:{"slot":"hooper-addons"},slot:"hooper-addons"})],2),_c('div',[_c('div',{staticClass:"pt-2 md:h-72 lg:h-80"},[_c('p',{staticClass:"pb-3 text-xl font-semibold leading-tight text-gray-900"},[_vm._v(" "+_vm._s(item.alt)+" ")]),_c('p',{staticClass:"text-sm text-gray-600",class:item.style},[_vm._v(" "+_vm._s(item.p)+" ")]),_c('ul',{staticClass:"pt-4 pl-6 text-sm text-gray-600 list-disc list-outside"},_vm._l((item.sublist),function(list){return _c('li',{key:list},[_vm._v(_vm._s(list))])}),0),_vm._l((item.p2),function(text){return _c('p',{directives:[{name:"show",rawName:"v-show",value:(item.p2),expression:"item.p2"}],key:text,staticClass:"pt-3 text-sm text-gray-600"},[_vm._v(" "+_vm._s(text)+" ")])})],2),_c('div',{staticClass:"pt-4"},[_c('p',{staticClass:"py-1 text-center text-white bg-gray-700"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#element', duration: 1500 }),expression:"{ el: '#element', duration: 1500 }"}],attrs:{"href":"#"}},[_vm._v("Get A Quote")])])])])],1)}),0)])])]),_c('div',{},[_c('carousel',{attrs:{"autoplay":true,"loop":true,"dots":false,"autoplayTimeout":5500,"responsive":{
        0: { items: 1, nav: false },
        600: { items: 2, nav: false },
        1000: { items: 3, nav: false },
        1500: { items: 4, nav: false },
      }}},_vm._l((_vm.gallery),function(item,i){return _c('div',{key:i,staticClass:"relative z-0"},[_c('img',{attrs:{"src":item.image,"alt":item.alt}}),_c('div',{staticClass:"absolute bottom-3 left-3"},[_c('p',{staticClass:"w-40 py-1 text-center bg-gray-200 lg:w-52 text-700"},[_vm._v(" "+_vm._s(item.alt)+" ")])])])}),0)],1),_c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_vm._m(5),_c('div',{staticClass:"lg:flex lg:items-center"},[_vm._m(6),_c('div',{staticClass:"pt-5 lg:w-1/2 lg:pl-4 lg:pt-0"},_vm._l((_vm.impact),function(item,i){return _c('div',{key:i,staticClass:"flex pb-5"},[_c('h1',{staticClass:"text-6xl font-semibold text-gray-700"},[_vm._v(" "+_vm._s(item.h1)+" ")]),_c('p',{staticClass:"pl-3 text-base text-gray-600"},[_vm._v(" "+_vm._s(item.p)+" ")])])}),0)])])]),_vm._m(7),_c('div',{staticClass:"py-10 bg-center bg-no-repeat bg-cover",staticStyle:{"background-image":"url('/images/sub-banner-1.jpg')"},attrs:{"id":"element"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"w-5/6 mx-auto md:w-3/4"},[_c('div',{staticClass:"px-3 py-6 bg-white shadow-md"},[_c('p',{staticClass:"pb-4 text-2xl font-semibold leading-tight text-center text-gray-700 md:text-3xl md:pb-5"},[_vm._v(" Get Our Catalogue and Quotation ")]),_c('p',{staticClass:"pb-4 text-sm text-center text-gray-600"},[_vm._v("We will send out our latest PDF catalogue and get back to you within 24 hours.")]),_c('EnquiryForm')],1)])])]),_vm._m(8),_vm._m(9),_vm._m(10)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fixed z-10 w-full py-4 bg-white shadow-md"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex items-center justify-between md:justify-center md:flex-wrap"},[_c('img',{staticClass:"w-40 mx-auto md:w-52",attrs:{"src":"/images/main-logo.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-12 bg-gray-50"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pb-4 text-center"},[_c('h1',{staticClass:"text-2xl font-semibold text-gray-700"},[_vm._v(" How Do Your Gifts Give Back? ")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 w-0.5"})]),_c('div',{staticClass:"space-y-2 text-lg leading-tight text-center text-gray-600 lg:max-w-3xl lg:mx-auto"},[_c('p',[_vm._v(" Every gift purchase directly impacts the lives of our team of indigenous Orang Asli mothers, providing work opportunities and income to support their children's education. ")]),_c('p',[_vm._v(" We curate a range of locally sourced, socially sustainable products that matches your gifting needs from local social enterprises and small businesses. ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4 text-center"},[_c('h1',{staticClass:"text-2xl font-semibold text-gray-700"},[_vm._v("Why Choose Us")]),_c('p',{staticClass:"pt-3 text-lg leading-tight text-gray-600 lg:max-w-3xl lg:mx-auto"},[_vm._v(" We produce premium gifts that support the livelihood of Orang Asli women in peninsula Malaysia. Each purchase you make provides work opportunities for Orang Asli women. ")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 w-0.5"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4 text-center"},[_c('h1',{staticClass:"text-2xl font-semibold leading-tight text-gray-700"},[_vm._v(" Trusted By The World's Leading Brands ")]),_c('p',{staticClass:"pt-3 text-lg leading-tight text-gray-600 lg:max-w-3xl lg:mx-auto"},[_vm._v(" Deliver on ESG & SDG goals and reflect your support for sustainability and conscious consumerism with our environmental and social gifting selection. ")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 w-0.5"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4 text-center"},[_c('h1',{staticClass:"text-2xl font-semibold text-gray-700"},[_vm._v("Our Collection")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 w-0.5"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-4 text-center"},[_c('h1',{staticClass:"text-2xl font-semibold text-gray-700"},[_vm._v("Our Impact")]),_c('p',{staticClass:"pt-3 text-lg leading-tight text-gray-600 lg:max-w-3xl lg:mx-auto"},[_vm._v(" We have channelled RM1,140,041 towards Orang Asli Communities in Peninsula Malaysia by providing materials, equipment, training & wages. ")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 w-0.5"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"md:w-2/3 lg:w-1/2 lg:mx-0 md:mx-auto"},[_c('img',{staticClass:"xl:w-2/3 xl:mx-auto",attrs:{"src":"/images/bg-about-m.jpg","alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pb-4 text-center"},[_c('h1',{staticClass:"text-2xl font-semibold text-gray-700"},[_vm._v("About Us")]),_c('p',{staticClass:"pt-3 text-lg leading-tight text-gray-600"},[_vm._v(" The Asli Co. aspires to eradicate poverty amongst the indigenous people of Malaysia, the Orang Asli. ")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 w-0.5"})]),_c('div',{staticClass:"lg:flex lg:items-center"},[_c('div',{staticClass:"md:w-2/3 lg:w-1/2 lg:mx-0 md:mx-auto"},[_c('img',{staticClass:"md:mx-auto",attrs:{"src":"/images/team.jpg","alt":""}})]),_c('div',{staticClass:"pt-4 space-y-3 text-sm text-center text-gray-600 lg:text-base lg:pl-4 lg:text-left lg:w-1/2 lg:pt-0"},[_c('p',[_vm._v(" The Asli Co. was founded in January 2019 by co-founders Jason & Xin, both long-time volunteers who built houses for OA communities. They found out that many kids in the villages drop out of school because parents couldn’t afford the expenses such as school fees, uniforms, stationeries and so on. ")]),_c('p',[_vm._v(" So they started with a simple idea of bringing work to the Orang Asli ladies in the kampung to help them cover these expenses. They started with teaching Anisah in Kg. Orang Asli Serendah to make our pilot product, the succulent cement pots. ")]),_c('p',[_vm._v(" To date, they’re grateful to be able to touch the lives of more than 60 lady artisans who produce our entire range of products. ")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10 bg-gray-50"},[_c('div',{staticClass:"container xl:max-w-4xl"},[_c('div',{staticClass:"md:flex md:items-center"},[_c('div',{staticClass:"pb-4 text-center md:pb-0 md:w-1/3"},[_c('h1',{staticClass:"text-2xl font-semibold text-gray-700 md:p-4 md:border-2 md:border-gray-800 md:text-left md:text-4xl"},[_vm._v(" Gift Delivery Coverage ")]),_c('div',{staticClass:"bg-gray-500 h-5 mx-auto my-3 md:hidden w-0.5"})]),_c('div',{staticClass:"pl-3 md:pl-10 md:w-2/3"},[_c('ul',{staticClass:"space-y-2 text-base leading-tight text-gray-600 list-disc list-outside"},[_c('li',[_vm._v("Free delivery in Klang Valley for orders over RM1000.")]),_c('li',[_vm._v(" We can arrange shipping to individual addresses working from home. ")]),_c('li',[_vm._v(" Shipping to Sabah/Sarawak/Singapore available for non-liquid products. ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-10"},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"mx-auto w-52 lg:w-60",attrs:{"src":"/images/main-logo.png","alt":""}}),_c('div',{staticClass:"pt-3 text-center"},[_c('h2',{staticClass:"text-gray-600 transition duration-200 ease-in-out transform hover:scale-110 hover:text-green-1"},[_vm._v(" Get A Quote: "),_c('a',{attrs:{"href":"https://wa.me/60167703372?text=Hi,%20please%20send%20me%20your%20corporate%20gifting%20catalogue.%20Name:%20|%20Company:%20|%20Number%20of%20gift%20sets:%20|%20Budget:"}},[_vm._v("+60167703372")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"py-3 bg-black-1"},[_c('div',{staticClass:"container mx-auto"},[_c('h2',{staticClass:"text-xs text-center text-white lg:text-sm"},[_vm._v(" Website maintained by Activa Media. All Rights Reserved. ")])])])
}]

export { render, staticRenderFns }