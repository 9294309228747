<template>
  <div>
    <WaBtn />
    <!-- header  -->
    <div class="fixed z-10 w-full py-4 bg-white shadow-md">
      <div class="container">
        <div class="flex items-center justify-between md:justify-center md:flex-wrap">
          <img src="/images/main-logo.png" alt="" class="w-40 mx-auto md:w-52" />
          <!-- <div class="hidden pt-2 text-center md:block md:w-full">
            <p
              class="text-base font-normal transition duration-200 transform hover:scale-110"
            >
              Call Us At <a href="tel:+60167703372">016-770 3372</a>
            </p>
          </div> -->
          <!-- <div>
            <a href="tel:+60167703372" class="md:hidden">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="30"
                height="30"
                class="text-gray-500 transition duration-200 ease-linear transform fill-current hover:scale-110 hover:text-green-1"
              >
                <path fill="none" d="M0 0h24v24H0z" />
                <path
                  d="M21 16.42v3.536a1 1 0 0 1-.93.998c-.437.03-.794.046-1.07.046-8.837 0-16-7.163-16-16 0-.276.015-.633.046-1.07A1 1 0 0 1 4.044 3H7.58a.5.5 0 0 1 .498.45c.023.23.044.413.064.552A13.901 13.901 0 0 0 9.35 8.003c.095.2.033.439-.147.567l-2.158 1.542a13.047 13.047 0 0 0 6.844 6.844l1.54-2.154a.462.462 0 0 1 .573-.149 13.901 13.901 0 0 0 4 1.205c.139.02.322.042.55.064a.5.5 0 0 1 .449.498z"
                />
              </svg>
            </a>
          </div> -->
        </div>
      </div>
    </div>

    <!-- main banner -->
    <MainBanner />

    <!-- sub section  -->
    <div class="py-12 bg-gray-50">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-2xl font-semibold text-gray-700">
            How Do Your Gifts Give Back?
          </h1>
          <div class="bg-gray-500 h-5 mx-auto my-3 w-0.5"></div>
        </div>
        <div class="space-y-2 text-lg leading-tight text-center text-gray-600 lg:max-w-3xl lg:mx-auto">
          <p>
            Every gift purchase directly impacts the lives of our team of
            indigenous Orang Asli mothers, providing work opportunities and
            income to support their children's education.
          </p>
          <p>
            We curate a range of locally sourced, socially sustainable products
            that matches your gifting needs from local social enterprises and
            small businesses.
          </p>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div class="py-10">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-2xl font-semibold text-gray-700">Why Choose Us</h1>
          <p class="pt-3 text-lg leading-tight text-gray-600 lg:max-w-3xl lg:mx-auto">
            We produce premium gifts that support the livelihood of Orang Asli
            women in peninsula Malaysia. Each purchase you make provides work
            opportunities for Orang Asli women.
          </p>
          <div class="bg-gray-500 h-5 mx-auto my-3 w-0.5"></div>
        </div>

        <div class="flex flex-wrap pt-2 lg:justify-center">
          <div v-for="(item, i) in choose_us" :key="i" class="w-1/2 pb-5 lg:w-1/4">
            <img :src="item.image" :alt="item.alt" class="w-20 mx-auto" />
            <div class="pt-3 leading-tight text-center text-gray-600">
              <p>{{ item.alt }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Our testimonials -->
    <div class="pt-5 pb-10">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-2xl font-semibold leading-tight text-gray-700">
            Trusted By The World's Leading Brands
          </h1>
          <p class="pt-3 text-lg leading-tight text-gray-600 lg:max-w-3xl lg:mx-auto">
            Deliver on ESG & SDG goals and reflect your support for
            sustainability and conscious consumerism with our environmental and
            social gifting selection.
          </p>
          <div class="bg-gray-500 h-5 mx-auto my-3 w-0.5"></div>
        </div>

        <!-- content -->
        <div class="pt-6">
          <carousel :autoplay="true" :loop="true" :dots="false" :autoplayTimeout="5000" :responsive="{
            0: { items: 3, nav: false },
            600: { items: 5, nav: false },
            1000: { items: 6, nav: false },
          }">
            <div v-for="image in testimonial" :key="image" class="px-1">
              <img :src="image" alt="" class="" />
            </div>
          </carousel>
          <carousel :autoplay="true" :loop="true" :dots="false" :autoplayTimeout="5700" :responsive="{
            0: { items: 3, nav: false },
            600: { items: 5, nav: false },
            1000: { items: 6, nav: false },
          }">
            <div v-for="image in testimonial_2" :key="image" class="px-1">
              <img :src="image" alt="" class="" />
            </div>
          </carousel>
          <carousel :autoplay="true" :loop="true" :dots="false" :autoplayTimeout="5500" :responsive="{
            0: { items: 3, nav: false },
            600: { items: 5, nav: false },
            1000: { items: 6, nav: false },
          }">
            <div v-for="image in testimonial_3" :key="image" class="px-1">
              <img :src="image" alt="" class="" />
            </div>
          </carousel>
        </div>
      </div>
    </div>

    <!-- product -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-2xl font-semibold text-gray-700">Our Collection</h1>
          <div class="bg-gray-500 h-5 mx-auto my-3 w-0.5"></div>
        </div>
        <div class="">
          <div
            class="p-3 border border-gray-200 lg:shadow-md md:flex md:flex-wrap"
          >
            <div
              v-for="(item, i) in product"
              :key="i"
              class="pb-4 lg:w-1/3 md:w-1/2 md:px-2"
            >
              <!-- <img
                :src="item.image"
                :alt="item.alt"
                class="w-2/3 mx-auto md:w-full"
              /> -->
              <hooper :itemsToShow="1" :centerMode="true" :wheelControl="false">
                <slide
                  v-for="(slide, indx) in item.slides"
                  :key="indx"
                  :index="indx"
                >
                  <img :src="slide" alt="" class="w-full" />
                </slide>
                <hooper-navigation slot="hooper-addons"></hooper-navigation>
              </hooper>
              <div>
                <div class="pt-2 md:h-72 lg:h-80">
                  <p
                    class="pb-3 text-xl font-semibold leading-tight text-gray-900"
                  >
                    {{ item.alt }}
                  </p>
                  <p class="text-sm text-gray-600" :class="item.style">
                    {{ item.p }}
                  </p>
                  <ul
                    class="pt-4 pl-6 text-sm text-gray-600 list-disc list-outside"
                  >
                    <li v-for="list in item.sublist" :key="list">{{ list }}</li>
                  </ul>
                  <p
                    class="pt-3 text-sm text-gray-600"
                    v-show="item.p2"
                    v-for="text in item.p2"
                    :key="text"
                  >
                    {{ text }}
                  </p>
                </div>
                <div class="pt-4">
                  <p class="py-1 text-center text-white bg-gray-700">
                    <a href="#" v-scroll-to="{ el: '#element', duration: 1500 }"
                      >Get A Quote</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- gallery  -->
    <div class="">
      <carousel
        :autoplay="true"
        :loop="true"
        :dots="false"
        :autoplayTimeout="5500"
        :responsive="{
          0: { items: 1, nav: false },
          600: { items: 2, nav: false },
          1000: { items: 3, nav: false },
          1500: { items: 4, nav: false },
        }"
      >
        <div v-for="(item, i) in gallery" :key="i" class="relative z-0">
          <img :src="item.image" :alt="item.alt" />
          <div class="absolute bottom-3 left-3">
            <p class="w-40 py-1 text-center bg-gray-200 lg:w-52 text-700">
              {{ item.alt }}
            </p>
          </div>
        </div>
      </carousel>
    </div>

    <!-- subbanner -->
    <div class="py-10">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-2xl font-semibold text-gray-700">Our Impact</h1>
          <p class="pt-3 text-lg leading-tight text-gray-600 lg:max-w-3xl lg:mx-auto">
            We have channelled RM1,140,041 towards Orang Asli Communities in
            Peninsula Malaysia by providing materials, equipment, training &
            wages.
          </p>
          <div class="bg-gray-500 h-5 mx-auto my-3 w-0.5"></div>
        </div>
        <div class="lg:flex lg:items-center">
          <div class="md:w-2/3 lg:w-1/2 lg:mx-0 md:mx-auto">
            <img
              src="/images/bg-about-m.jpg"
              alt=""
              class="xl:w-2/3 xl:mx-auto"
            />
          </div>
          <div class="pt-5 lg:w-1/2 lg:pl-4 lg:pt-0">
            <div v-for="(item, i) in impact" :key="i" class="flex pb-5">
              <h1 class="text-6xl font-semibold text-gray-700">
                {{ item.h1 }}
              </h1>
              <p class="pl-3 text-base text-gray-600">
                {{ item.p }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div class="py-10">
      <div class="container">
        <div class="pb-4 text-center">
          <h1 class="text-2xl font-semibold text-gray-700">About Us</h1>
          <p class="pt-3 text-lg leading-tight text-gray-600">
            The Asli Co. aspires to eradicate poverty amongst the indigenous
            people of Malaysia, the Orang Asli.
          </p>
          <div class="bg-gray-500 h-5 mx-auto my-3 w-0.5"></div>
        </div>
        <div class="lg:flex lg:items-center">
          <div class="md:w-2/3 lg:w-1/2 lg:mx-0 md:mx-auto">
            <img src="/images/team.jpg" alt="" class="md:mx-auto" />
          </div>
          <div
            class="pt-4 space-y-3 text-sm text-center text-gray-600 lg:text-base lg:pl-4 lg:text-left lg:w-1/2 lg:pt-0"
          >
            <p>
              The Asli Co. was founded in January 2019 by co-founders Jason &
              Xin, both long-time volunteers who built houses for OA
              communities. They found out that many kids in the villages drop
              out of school because parents couldn’t afford the expenses such as
              school fees, uniforms, stationeries and so on.
            </p>
            <p>
              So they started with a simple idea of bringing work to the Orang
              Asli ladies in the kampung to help them cover these expenses. They
              started with teaching Anisah in Kg. Orang Asli Serendah to make
              our pilot product, the succulent cement pots.
            </p>
            <p>
              To date, they’re grateful to be able to touch the lives of more than
              60 lady artisans who produce our entire range of products.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- enquiry form  -->
    <div
      id="element"
      class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/sub-banner-1.jpg')"
    >
      <div class="container">
        <div class="w-5/6 mx-auto md:w-3/4">
          <div class="px-3 py-6 bg-white shadow-md">
            <p
              class="pb-4 text-2xl font-semibold leading-tight text-center text-gray-700 md:text-3xl md:pb-5"
            >
              Get Our Catalogue and Quotation
            </p>
            <p class="pb-4 text-sm text-center text-gray-600">We will send out our latest PDF catalogue and get back to you within 24 hours.</p>
            <EnquiryForm />
          </div>
        </div>
      </div>
    </div>

    <div class="py-10 bg-gray-50">
      <div class="container xl:max-w-4xl">
        <div class="md:flex md:items-center">
          <div class="pb-4 text-center md:pb-0 md:w-1/3">
            <h1
              class="text-2xl font-semibold text-gray-700 md:p-4 md:border-2 md:border-gray-800 md:text-left md:text-4xl"
            >
              Gift Delivery Coverage
            </h1>
            <div class="bg-gray-500 h-5 mx-auto my-3 md:hidden w-0.5"></div>
          </div>
          <div class="pl-3 md:pl-10 md:w-2/3">
            <ul
              class="space-y-2 text-base leading-tight text-gray-600 list-disc list-outside"
            >
              <li>Free delivery in Klang Valley for orders over RM1000.</li>
              <li>
                We can arrange shipping to individual addresses working from
                home.
              </li>
              <li>
                Shipping to Sabah/Sarawak/Singapore available for non-liquid
                products.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

    <!-- footer  -->
    <div class="py-10">
      <div class="container">
        <img src="/images/main-logo.png" alt="" class="mx-auto w-52 lg:w-60" />
        <div class="pt-3 text-center">
          <h2
            class="text-gray-600 transition duration-200 ease-in-out transform hover:scale-110 hover:text-green-1"
          >
            Get A Quote: <a href="https://wa.me/60167703372?text=Hi,%20please%20send%20me%20your%20corporate%20gifting%20catalogue.%20Name:%20|%20Company:%20|%20Number%20of%20gift%20sets:%20|%20Budget:">+60167703372</a>
          </h2>
        </div>
      </div>
    </div>
    
    <!-- Copyright -->
    <div class="py-3 bg-black-1">
      <div class="container mx-auto">
        <h2 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import MainBanner from '@/components/MainBanner.vue';
import WaBtn from '@/components/WaBtn.vue';
import carousel from 'vue-owl-carousel';
import EnquiryForm from '@/components/EnquiryForm.vue';
import 'hooper/dist/hooper.css';
import { Hooper, Slide, Navigation as HooperNavigation } from 'hooper';

// @ is an alias to /src


export default {
  name: 'HomeView',
  components: {
    WaBtn,
    MainBanner,
    carousel,
    EnquiryForm,
    Hooper,
    Slide,
    HooperNavigation,
  },
  data() {
    return {
      product: [
        {
          slides:[
            '/images/product-1.jpg',
            '/images/product-1-1.jpg',
          ],
          alt: "Lavender Aromatherapy Eye Pillow",
          style: "italic font-bold",
          p: "“ I Sleep like baby! Smells soothing and great. The heat is just right when warmed via microwave.The satin silk pillow is so soft on my eyes. The size is great and the heat it captures and transmits is just nice to sooth my eyes. ” - Zatul I",
          sublist: [
            "Filled with 100% natural organic flax seeds and Lavender",
            "Relieve tired eyes and sinus congestion",
            "Soothe headaches and aids sleep",
            "Microwave to heat up for hot therapy",
            "Leave it in the freezer for cool therapy",
          ],
        },
        {
          slides:[
            '/images/product-2.jpg',
            '/images/product-2-1.jpg',
          ],
          alt: "Lavender Pillow Mist",
          style: "italic font-bold",
          p: "“ I’m so in love with this lavender pillow mist. The scent is perfectly relaxing and calming. Not too strong and not too light. I slept so well last night after using this spray. ” - Foong L",
          sublist: [
            "Contains 100% Pure Lavender Essential Oil plus a touch of Sweet Orange Essential Oil",
            "Anti-anxiety, relieves stress",
            "Puts you in a perfect mood for sleep",
          ],
        },
        {
          slides:[
            '/images/product-3.jpg',
            '/images/product-3-1.jpg',
          ],
          alt: "Customizable Gift Set",
          p: "Includes:",
          sublist: [
            "1 X Lavender Eye Pillow",
            "1 X Lavender Pillow Mist ",
            "1 X Lavender Sea Shell Hand Soap",
            "1 X Custom note card",
          ],
          p2: [
            "Comes in a beautiful hardcover box with ribbon and gift tag.",
            "Items in the box are completely customizable.Contact us for a quotation",
          ],
        },
      ],
      choose_us: [
        {
          image: "/images/icon-2.png",
          alt: "Give back to the Orang Asli community",
        },
        {
          image: "/images/icon-1.png",
          alt: "Fast production times",
        },
        {
          image: "/images/icon-3.png",
          alt: "Option to ship to individual address",
        },
        {
          image: "/images/icon-4.png",
          alt: "Customizable logo on products",
        },
      ],
      gallery: [
        { image: "/images/gallery-1.jpg", alt: "Christmas Gift Set" },
        { image: "/images/gallery-2.jpg", alt: "Relaxation Gift Set" },
        { image: "/images/gallery-3.jpg", alt: "Shopee Gift Set" },
        { image: "/images/gallery-4.jpg", alt: "SME Gift Set" },
        { image: "/images/gallery-5.jpg", alt: "AIA Gift Set" },
        { image: "/images/gallery-6.jpg", alt: "Dusun Fruit Set" },
        { image: "/images/gallery-7.jpg", alt: "Bubble Candle" },
        { image: "/images/gallery-8.jpg", alt: "Raya Gift Set" },
        { image: "/images/gallery-9.jpg", alt: "Sime Darby Raya Gift Set" },
        // { image: "/images/gallery-10.jpg", alt: "Product 10" },
        // { image: "/images/gallery-11.jpg", alt: "Product 11" },
      ],
      impact: [
        {
          h1: "1",
          p: "64 Orang Asli mothers upskilled and empowered",
        },
        {
          h1: "2",
          p: "We support artisans from 8 villages around Selangor, Pahang, Perak and Negeri Sembilan.",
        },
        {
          h1: "3",
          p: " Mothers were able to put food on the table during the pandemic when their husbands were out of jobs.",
        },
        // {
        //   h1: "4",
        //   p: "Orang Asli mothers are paid up to 4 times the minimum hourly wage",
        // },
      ],
      testimonial: [
        "/images/client/logo-1.jpg",
        "/images/client/logo-2.jpg",
        "/images/client/logo-3.jpg",
        "/images/client/logo-4.jpg",
        "/images/client/logo-5.jpg",
        "/images/client/logo-6.jpg",
        "/images/client/logo-7.jpg",
        "/images/client/logo-8.jpg",
        "/images/client/logo-9.jpg",
        "/images/client/logo-10.jpg",
      ],
      testimonial_2: [
        "/images/client/logo-11.jpg",
        "/images/client/logo-12.jpg",
        "/images/client/logo-13.jpg",
        "/images/client/logo-14.jpg",
        "/images/client/logo-15.jpg",
        "/images/client/logo-16.jpg",
        "/images/client/logo-17.jpg",
        "/images/client/logo-18.jpg",
        "/images/client/logo-19.jpg",
        "/images/client/logo-20.jpg",
      ],
      testimonial_3: [
        "/images/client/logo-21.jpg",
        "/images/client/logo-22.jpg",
        "/images/client/logo-23.jpg",
        "/images/client/logo-24.jpg",
        "/images/client/logo-25.jpg",
        "/images/client/logo-26.jpg",
        "/images/client/logo-27.jpg",
        "/images/client/logo-28.jpg",
        "/images/client/logo-29.jpg",
        "/images/client/logo-30.jpg",
      ],
    };
  },
}
</script>

<style>
/* .bg-banner {
  background-image: url("/images/main-banner-m.jpg");
} */
.bg-height {
  min-height: 650px;
}
.hooper-next,
.hooper-prev {
  background-color: #fff;
  opacity: 0.8;
  top: 50%;
}
.hooper {
    height: auto;
}

/* @media (min-width: 768px) {
  .bg-banner {
    background-image: url("/images/main-banner.jpg");
  }
} */
</style>
