<template>
    <form @submit.prevent="submit" class="mx-auto space-y-4">
      <div>
        <div
          class="border border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <label for="name" class="text-sm text-gray-500"
            >Name <span class="text-gray-400">*</span></label
          >
          <input
            v-model="fields.name"
            id="name"
            type="text"
            class="text-gray-600 focus:outline-none"
          />
        </div>
        <InputError
          :field="errors.name"
          class="text-sm font-medium text-red-600"
        />
      </div>
      <div>
        <div
          class="border border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <label for="email" class="text-sm text-gray-500"
            >Company Email <span class="text-gray-400">*</span></label
          >
          <input
            v-model="fields.email"
            id="email"
            type="email"
            class="text-gray-600 focus:outline-none"
          />
        </div>
        <InputError
          :field="errors.email"
          class="text-sm font-medium text-red-600"
        />
      </div>
      <div>
        <div
          class="border border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <label for="contact_number" class="text-sm text-gray-500"
            >Contact Number <span class="text-gray-400">*</span></label
          >
          <input
            v-model="fields.contact"
            id="contact_number"
            type="tel"
            placeholder="012-3456789"
            class="text-gray-600 focus:outline-none"
          />
        </div>
        <InputError
          :field="errors.contact"
          class="text-sm font-medium text-red-600"
        />
      </div>
  
      <div>
        <div
          class="border border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <label for="budget_per_box" class="text-sm text-gray-500"
            > Budget Per Gift Set <span class="text-gray-400">*</span></label
          >
          <input
            v-model="fields.budget_per_box"
            id="budget_per_box"
            type="text"
            class="text-gray-600 focus:outline-none"
          />
        </div>
        <InputError
          :field="errors.budget_per_box"
          class="text-sm font-medium text-red-600"
        />
      </div>
  
      <div>
        <label for="selected" class="text-sm text-gray-600"
          >Number of Gift Sets  <span class="text-red-900">*</span></label
        >
        <select
          v-model="fields.selected"
          type="text"
          class="border w-full text-gray-600 text-sm border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <option disabled>Please select one</option>
          <option value="50-100">50 – 100</option>
          <option value="100-300">100 – 300</option>
          <option value="300-500">300 – 500</option>
          <option value="500 and above">500 and above</option>
        </select>
  
        <InputError
          :field="errors.selected"
          class="text-sm font-medium text-red-600"
        />
      </div>
  
      <div>
        <div
          class="border border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <label for="occasion_for_gift" class="text-sm text-gray-500"
            > Occasion for Gift <span class="text-gray-400">*</span></label
          >
          <input
            v-model="fields.occasion_for_gift"
            id="occasion_for_gift"
            type="text"
            class="text-gray-600 focus:outline-none"
          />
        </div>
        <InputError
          :field="errors.occasion_for_gift"
          class="text-sm font-medium text-red-600"
        />
      </div>
  
      <div>
        <div
          class="border border-gray-300 rounded flex flex-col py-1.5 px-2 bg-white"
        >
          <label for="delivery" class="text-sm text-gray-500"
            >Ideal Delivery Date <span class="text-gray-400">*</span></label
          >
          <input
            v-model="fields.delivery"
            id="delivery"
            type="text"
            class="text-gray-600 focus:outline-none"
          />
        </div>
        <InputError
          :field="errors.delivery"
          class="text-sm font-medium text-red-600"
        />
      </div>
      <div class="pb-2">
        <p class="text-sm text-red-900">* required field </p>
      </div>
      <div class="w-2/3 mx-auto">
        <button
          v-text="busy ? 'Submitting...' : 'Submit'"
          type="submit"
          class="w-full py-2 text-lg font-medium text-white transition bg-gray-700 border-none opacity-90 hover:opacity-100 disabled:cursor-wait disabled:opacity-50"
        ></button>
      </div>
    </form>
  </template>
  
  <script>
  import { InputError, hapiMixins } from "@juno0424/vue2-hapi-inputs";
  
  export default {
    mixins: [hapiMixins],
    components: { InputError },
    data() {
      return {
        endpoint: "https://hapiform.sg/api/efd84d66-6eb5-497f-98f3-7c79b744faf9",
        redirectTo: "/thank-you",
      };
    },
  };
  </script>
  